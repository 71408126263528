import styled from '@emotion/styled';
import {ChevronLeft, ChevronRight, ExpandLess, ExpandMore} from '@mui/icons-material';
import {Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import {ReactComponent as KmMidiaLogoWhite} from '../assets/icons/km-midia-logo-white.svg';
import {ReactComponent as IconKm} from '../assets/icons/km-symbol.svg';
import SidebarRoutes from '../constants/sidebar-routes';
import {setOpenSidebar} from '../redux/Sidebar/slice';

export const SidebarComponent = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();

  const {sidebarOpen} = useSelector((state) => state.Sidebar);

  const [arialCurrentRoute, setArialCurrentRoute] = useState('');

  const arrayRouter = SidebarRoutes.getSidebarRoutes();

  useEffect(() => {
    const mainPath = pathname?.split('/')[1];
    setArialCurrentRoute(`/${mainPath}`);
  }, [pathname]);

  const renderTestHeader = () => {
    if (!window.location.host.includes('homologacao.kmmidia.com')) return '';

    return <Typography sx={styles.testesHeader}>{sidebarOpen ? 'AMBIENTE DE TESTE' : 'TESTE'}</Typography>;
  };

  return (
    <Drawer variant="permanent" open={sidebarOpen} sx={{zIndex: (theme) => theme.zIndex.sidebar}}>
      {renderTestHeader()}
      <List sx={styles.nav}>
        <Box sx={styles.logo}>{sidebarOpen ? <KmMidiaLogoWhite style={styles.kmLogo} /> : <IconKm />}</Box>
        {arrayRouter?.map((item, index) => {
          return (
            <ListComponent
              index={index}
              key={index}
              title={item.title}
              route={item.route}
              icon={item.icon}
              sidebarOpen={sidebarOpen}
              options={item.options || []}
              arialCurrentRoute={arialCurrentRoute}
            />
          );
        })}
      </List>
      <Box
        sx={{
          ...styles.sidebarItem,
          ...styles.sidebarExpandirItem,
          alignItems: sidebarOpen ? 'flex-start' : 'center',
        }}
        onClick={() => dispatch(setOpenSidebar(!sidebarOpen))}>
        <ListItemButton title={sidebarOpen ? 'Reduzir Menu' : 'Expandir Menu'} sx={itemButton(sidebarOpen)}>
          {sidebarOpen && <Typography sx={styles.reduzirMenuLabel}>Reduzir Menu</Typography>}
          <ListItemIcon sx={{minWidth: 0, justifyContent: 'center'}}>{sidebarOpen ? <ChevronLeft sx={styles.expandirIcon} /> : <ChevronRight sx={styles.expandirIcon} />}</ListItemIcon>
        </ListItemButton>
      </Box>
    </Drawer>
  );
};

const ListComponent = ({route, title, arialCurrentRoute, sidebarOpen, icon, options = [], index}) => {
  const [openCollapseItem, setOpenCollapseItem] = useState(true);
  const [openModalSidebar, setOpenModalSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const checkArialCurrent = () => {
    if (options.some((item) => item.route === arialCurrentRoute)) return 'true';
    if (arialCurrentRoute === route || arialCurrentRoute === title) return 'true';
    return null;
  };

  const expansiveIcon = (options) => {
    if (options.length === 0) return;
    if (openCollapseItem) return <ExpandLess />;
    return <ExpandMore />;
  };

  const handleClickListButton = (event) => {
    setOpenCollapseItem(!openCollapseItem);
    setOpenModalSidebar(!sidebarOpen && true);
    setAnchorEl(event.currentTarget);
  };

  return (
    <ListItem
      component={options.length === 0 ? NavLink : null}
      to={options.length === 0 ? route : null}
      id={index}
      sx={{
        ...styles.sidebarItem,
        paddingLeft: sidebarOpen ? '0 !important' : '16px',
        paddingRight: sidebarOpen ? '0 !important' : '16px',
        alignItems: sidebarOpen ? 'flex-start' : 'center',
      }}
      aria-current={checkArialCurrent()}
      title={title}>
      <ListItemButton
        sx={itemButton(sidebarOpen)}
        disableRipple
        onClick={(e) => {
          handleClickListButton(e);
        }}>
        <ListItemIcon sx={itemIcon(sidebarOpen)}>{icon}</ListItemIcon>
        {sidebarOpen && (
          <ListItemText
            sx={{
              whiteSpace: 'nowrap',
            }}>
            {title}
          </ListItemText>
        )}
        {sidebarOpen && expansiveIcon(options)}
      </ListItemButton>
      {sidebarOpen ? (
        <Collapse in={openCollapseItem} timeout="auto" unmountOnExit sx={{width: '100%'}}>
          <List
            component="div"
            disablePadding
            sx={{
              width: '100%',
              background: '#10267E',
              boxShadow: 'inset 0 8px 6px -6px #00106b',
            }}>
            {sidebarOpen &&
              options.map((item, index) => {
                return (
                  <ListItemButton
                    component={NavLink}
                    to={item.route}
                    key={index}
                    sx={{
                      paddingLeft: '48px !important',
                      width: '100%',
                      ...styles.buttonItem,
                    }}>
                    <ListItemText primary={item.title} sx={styles.itemText} />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      ) : (
        <Popover
          anchorEl={anchorEl}
          slotProps={{
            paper: {
              sx: {background: '#00106b', ...styles.navMenu},
              onMouseLeave: () => setOpenModalSidebar(false),
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 70,
          }}
          open={options.length !== 0 && openModalSidebar}
          onClose={() => setOpenModalSidebar(false)}>
          {options.map((item, index) => {
            return (
              <ListItemButton
                component={NavLink}
                to={item.route}
                key={index}
                sx={{
                  width: '100%',
                  ...styles.buttonItem,
                }}>
                <ListItemText primary={item.title} sx={styles.itemText} />
              </ListItemButton>
            );
          })}
        </Popover>
      )}
    </ListItem>
  );
};

const Drawer = styled(MuiDrawer)(({theme, open}) => ({
  position: 'relative',
  ...(!open && {
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.sidebarWidth,
      display: 'flex',
      height: '100vh',
      background: `${theme.palette.sidebarBg.main} `,
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 7,
      direction: 'rtl',
    },
  }),
  ...(open && {
    '& .MuiDrawer-paper': {
      width: theme.sidebarWidthOpen,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      display: 'flex',
      height: '100vh',
      overflowX: 'hidden',
      background: `${theme.palette.sidebarBg.main} `,
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 7,
    },
  }),
}));

const itemButton = (open) => ({
  minHeight: 48,
  justifyContent: open ? 'initial' : 'center',
  paddingLeft: open ? '32px !important' : '16px',
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
  },
});

const itemIcon = (open) => ({
  minWidth: 0,
  mr: open ? 3 : 'auto',
  justifyContent: 'center',
});

const styles = {
  nav: {
    paddingTop: 0,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff0',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#fffa',
      borderRadius: '5px',
    },
    ' &::-webkit-scrollbar-thumb:hover': {
      background: '#000b46',
    },
  },
  navMenu: {
    overflowY: 'auto',
    maxHeight: '50%',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff0',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: (theme) => theme.palette.sidebarBg.main,
      borderRadius: '5px',
    },
    ' &::-webkit-scrollbar-thumb:hover': {
      background: '#000b46',
    },
  },
  logo: {
    marginLeft: '25px',
    marginBottom: '16px',
    marginTop: '16px',
    direction: 'ltr',
    overflowX: 'hidden',
  },
  sidebarItem: {
    minHeight: '75px',
    display: 'flex',
    overflowX: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    textDecoration: 'none',
    position: 'relative',
    color: '#fff',
    '& path': {
      fill: (theme) => theme.palette.sidebarItem.main,
    },
    '&[aria-current]': {
      background: (theme) => `${theme.palette.sidebarItemActiveBg.main} !important`,
      '&::after': {
        content: '""',
        position: 'absolute',
        right: '0px',
        top: '0px',
        background: (theme) => theme.palette.sidebarBg.main,
        height: '8px',
        width: '100%',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        background: (theme) => theme.palette.sidebarBg.main,
        height: '8px',
        width: '100%',
      },
    },
  },
  sidebarExpandirItem: {
    height: '45px !important',
    minHeight: '43px',
    overflow: 'hidden',
    background: '#0A2549',
    color: '#fff',
    '& path': {
      fill: '#fff',
    },
  },
  expandirIcon: {
    color: '#fff',
  },
  buttonItem: {
    '&[aria-current]': {
      background: (theme) => `${theme.palette.paterns.primaryHover} !important`,

      '& path': {
        fill: (theme) => `${theme.palette.sidebarItemActive.main} !important`,
      },
    },
  },
  itemText: {
    color: '#fff',
    whiteSpace: 'nowrap',
  },
  reduzirMenuLabel: {
    marginRight: 'auto !important',
    whiteSpace: 'nowrap',
    fontFamily: 'AktivGrotesk-Bold !important',
  },
  kmLogo: {
    width: '150px',
    height: '26px',
  },
  testesHeader: {
    width: '100%',
    textAlign: 'center',
    background: '#000',
    fontWeight: 'bold',
    color: 'yellow !important',
  },
};
